import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { scrollToTarget } from 'utils/browser';
import { formatAnchor } from 'utils/helpers';

import { IAnchorSubmenuProps } from './model';

import './AnchorSubmenu.scss';

const AnchorSubmenu: FC<IAnchorSubmenuProps> = ({ sectionBg, sections, sectionTheme }) => {
  const scrollCallback = useCallback((anchor: string) => () => scrollToTarget(anchor), []);
  const { label } = sectionBg?.[0]?.properties?.colorPicker || {};

  return (
    <div
      className={classnames('anchor-submenu', {
        [`${label}-default-bg`]: label,
        [`anchor-submenu--${sectionTheme}`]: sectionTheme,
      })}
      data-testid="anchor-submenu"
    >
      <ul className="anchor-submenu__list">
        {sections?.map(({ anchorName }) =>
          anchorName ? (
            <li key={formatAnchor(anchorName)} className="anchor-submenu__list-item">
              <button
                type="button"
                className="anchor-submenu__list-item-btn"
                onClick={scrollCallback(`${formatAnchor(anchorName)}`)}
              >
                <span>{anchorName}</span>
                <span
                  aria-hidden="true"
                  className="nf-icon-custom icon-arrow_forward_ios anchor-submenu__list-item-icon"
                />
              </button>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

export const query = graphql`
  fragment FragmentAnchorSubmenu on IAnchorSubmenu {
    sectionBg {
      properties {
        colorPicker {
          label
        }
      }
    }
    sectionTheme
  }
`;

export default AnchorSubmenu;
