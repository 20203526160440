import React, { FC } from 'react';
import { Link } from 'gatsby';

const BreadCrumbsItem: FC<AppPageDataTypes.IBreadCrumb> = ({ link, name, order }) => (
  <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
    {link ? (
      <Link to={link} itemProp="item">
        <span itemProp="name">{name}</span>
      </Link>
    ) : (
      <span itemProp="name" className="breadcrumbs__current-item">
        {name}
      </span>
    )}
    <meta itemProp="position" content={order} />
  </li>
);

export default BreadCrumbsItem;
