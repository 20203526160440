import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import useScreenRecognition from 'hooks/useScreenRecognition';
import BreadCrumbs from 'components/BreadCrumbs';
import { scrollToTarget } from 'utils/browser';
import NFButton from 'components/common/NFButton';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import AnchorSubmenu from 'components/AnchorSubmenu';

import { IBannerComponent } from './model';

import './Banner.scss';

const Banner: FC<IBannerComponent> = ({
  isComponentExist = true,
  title,
  titleRte,
  text,
  btn,
  type,
  theme,
  img,
  imageAlt,
  imgSecond,
  imageSecondAlt,
  logo,
  logoAlt,
  pageBreaker,
  bgBannerColor,
  breadcrumbs,
  subNavItems,
  activeLastItemBreadCrumbs,
  ariaLabel,
  hideTextOnMobile,
  hideImgOnMobile,
  titleColor,
  titleTag,
  doesTextUnderImageOnMobile,
  isDisplaySubnavigation,
  withAnchorNavigation,
  sectionAnchors,
  hideBreadcrumbs,
  submenuLink,
}): React.ReactElement | null => {
  const { isMobile } = useScreenRecognition({ isMobile: true });

  const bgColor = bgBannerColor ? ` ${bgBannerColor}-default-bg` : '';
  const TitleTag = titleTag || 'h1';

  const classes = classNames('nf-banner', {
    'nf-banner--has-img': type?.includes('hasImg'),
    'nf-banner--skew': type?.includes('imgSkew'),
    'nf-banner--sm-top-indent': type?.includes('imgSmTopIndent'),
    'nf-banner--md-top-indent': type?.includes('imgMdTopIndent'),
    'nf-banner--lg-top-indent': type?.includes('imgLgTopIndent'),
    'nf-banner--page-breaker': pageBreaker,
    'nf-banner--mobile-no-indent': !doesTextUnderImageOnMobile,
    [`nf-banner--${theme}`]: theme,
  });

  const classesBg = classNames('nf-banner__bg', {
    [bgColor]: bgColor,
  });

  const bannerImg = img && (
    <div className="banner-img">
      <UniversalImage
        imageAlt={imageAlt}
        img={img}
        wrapperClassName="nf-banner__image-frame"
        objectFitData={{ objectFit: 'contain' }}
        isLazyLoading={pageBreaker}
        backgroundColor="transparent"
      />
    </div>
  );

  const bannerImgSecond = imgSecond ? (
    <div className="banner-img banner-img-second">
      <UniversalImage
        imageAlt={imageSecondAlt}
        img={imgSecond}
        wrapperClassName="nf-banner__image-second-frame"
        objectFitData={{ objectFit: 'contain' }}
        isLazyLoading={pageBreaker}
        backgroundColor="transparent"
      />
    </div>
  ) : null;

  const scrollCallback = useCallback((anchor) => () => scrollToTarget(anchor), []);

  const textHolder = (
    <div className="nf-banner__text-holder">
      <div className="nf-banner__text-frame">
        {logo ? (
          <UniversalImage imageAlt={logoAlt} img={logo} wrapperClassName="nf-banner__logo" />
        ) : null}
        {!hideBreadcrumbs && breadcrumbs?.length ? (
          <BreadCrumbs data={breadcrumbs} activeLastItem={activeLastItemBreadCrumbs} />
        ) : null}

        {titleRte ? (
          <DangerouslySetInnerHtml
            className="nf-banner__title"
            style={
              titleColor &&
              ({
                color: `var(--${titleColor})`,
              } as React.CSSProperties)
            }
            html={titleRte}
            element={TitleTag}
          />
        ) : (
          <TitleTag
            className="nf-banner__title"
            style={
              titleColor &&
              ({
                color: `var(--${titleColor})`,
              } as React.CSSProperties)
            }
          >
            {title}
          </TitleTag>
        )}

        {text ? (
          isMobile && hideTextOnMobile ? null : (
            <DangerouslySetInnerHtml className="nf-banner__text" html={text} />
          )
        ) : null}
        {isDisplaySubnavigation && subNavItems && subNavItems?.length > 0 ? (
          <ul className="nf-banner__subnav">
            {subNavItems.map((item) => {
              return (
                <li key={item.id}>
                  <button type="button" onClick={scrollCallback(`${item.id}`)}>
                    {item.title}
                  </button>
                </li>
              );
            })}
          </ul>
        ) : null}
        {btn ? <NFButton {...btn} /> : null}
      </div>
    </div>
  );

  return isComponentExist ? (
    <section
      className={classes}
      data-test="Banner"
      aria-label={ariaLabel}
      key={ariaLabel}
      {...(submenuLink ? { id: submenuLink } : {})}
    >
      <div className={classesBg} key={ariaLabel}>
        <Container fluid>
          <div className="nf-banner__frame" key={ariaLabel}>
            {isMobile ? (!doesTextUnderImageOnMobile ? textHolder : null) : null}
            {isMobile
              ? hideImgOnMobile
                ? null
                : [bannerImg, bannerImgSecond]
              : [bannerImg, bannerImgSecond]}
            {isMobile ? (doesTextUnderImageOnMobile ? textHolder : null) : textHolder}
            {withAnchorNavigation && sectionAnchors?.length ? (
              <AnchorSubmenu sections={sectionAnchors} />
            ) : null}
          </div>
        </Container>
      </div>
    </section>
  ) : null;
};

export const query = graphql`
  fragment FragmentBanner on IBanner {
    pageBannerTitle
    pageBannerTitleRte
    pageBannerTitleColor {
      ...FragmentColorProps
    }
    pageBannerText
    pageBannerDoesTextBlockUnderImageOnMobile
    pageBannerType
    pageBannerTheme
    pageBannerPageBreaker
    pageBannerIsBannerComponentExist
    pageBannerImageAlt
    pageBannerBannerBgColor {
      ...FragmentColorProps
    }
    pageBannerImage {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(
            maxWidth: $pageBannerImageSize
            srcSetBreakpoints: [400, 450, 500, 550, 600, 700, 1024, 1200]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
      focalPoint {
        x
        y
      }
    }
    pageBannerLogo {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    pageBannerLogoAlt
    pageBannerButtonData {
      properties {
        ...FragmentNFButton
      }
    }
    pageBannerHideTextOnMobile
    pageBannerHideImgOnMobile
    pageBannerAriaLabel
    pageBannerDisplaySubnavigation
    pageBannerWithAnchorNavigation
    pageBannerHideBreadcrumbs
    pageBannerSubmenuLink {
      ...FragmentSubmenuLink
    }
  }
`;

export default Banner;
