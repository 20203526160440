import React, { FC } from 'react';
import classNames from 'classnames';

import BreadCrumbsItem from 'components/BreadCrumbs/BreadCrumbsItem';

import { IBreadCrumbs } from './models';

import './BreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ data, activeLastItem }) => (
  <ul
    className={classNames('breadcrumbs', {
      'active-last-item': activeLastItem,
    })}
    itemScope
    itemType="http://schema.org/BreadcrumbList"
  >
    {data.map((item) => (
      <BreadCrumbsItem key={item.name} {...item} />
    ))}
  </ul>
);

export default BreadCrumbs;
